import React from "react";
import { FormattedMessage } from "react-intl";

const localize = ({ id, defaultMessage, description, values = [], debug = false }) => {
  return (
    <span>
      {!debug && <FormattedMessage id={id} defaultMessage={defaultMessage} description={description} values={values} />}
      {debug && (
        <FormattedMessage
          id={id}
          defaultMessage="<span></span>"
          description={description}
          values={{
            span: (chunks) => <span style={{ color: "red" }}>{defaultMessage}</span>
          }}
        />
      )}
    </span>
  );
};

export default localize;
