/* eslint-disable no-template-curly-in-string */
const getTranslations = (intl) => {
  const typeTemplate = intl.formatMessage({
    id: "antd.typeTemplate",
    defaultMessage: "${label} is not a valid ${type}",
    description: "Error message saying (label, wich is dynamic) is of the wrong (type, which is also dynamic)"
  });
  return {
    global: {
      placeholder: intl.formatMessage({ id: "antd.global.placeholder", defaultMessage: "Please select" })
    },
    Table: {
      filterTitle: intl.formatMessage({ id: "antd.table.filterTitle", defaultMessage: "Filter menu" }),
      filterConfirm: intl.formatMessage({ id: "antd.table.filterConfirm", defaultMessage: "OK" }),
      filterReset: intl.formatMessage({ id: "antd.table.filterReset", defaultMessage: "Reset" }),
      filterEmptyText: intl.formatMessage({ id: "antd.table.filterEmptyText", defaultMessage: "No filters" }),
      emptyText: intl.formatMessage({ id: "antd.table.emptyText", defaultMessage: "No data" }),
      selectAll: intl.formatMessage({ id: "antd.table.selectAll", defaultMessage: "Select current page" }),
      selectInvert: intl.formatMessage({ id: "antd.table.selectInvert", defaultMessage: "Invert current page" }),
      selectNone: intl.formatMessage({ id: "antd.table.selectNone", defaultMessage: "Clear all data" }),
      selectionAll: intl.formatMessage({ id: "antd.table.selectionAll", defaultMessage: "Select all data" }),
      sortTitle: intl.formatMessage({ id: "antd.table.sortTitle", defaultMessage: "Sort" }),
      expand: intl.formatMessage({ id: "antd.table.expand", defaultMessage: "Expand row" }),
      collapse: intl.formatMessage({ id: "antd.table.collapse", defaultMessage: "Collapse row" }),
      triggerDesc: intl.formatMessage({ id: "antd.table.triggerDesc", defaultMessage: "Click to sort descending" }),
      triggerAsc: intl.formatMessage({ id: "antd.table.triggerAsc", defaultMessage: "Click to sort ascending" }),
      cancelSort: intl.formatMessage({ id: "antd.table.cancelSort", defaultMessage: "Click to cancel sorting" })
    },
    Modal: {
      okText: intl.formatMessage({ id: "antd.modal.okText", defaultMessage: "OK" }),
      cancelText: intl.formatMessage({ id: "antd.modal.cancelText", defaultMessage: "Cancel" }),
      justOkText: intl.formatMessage({ id: "antd.modal.justOkText", defaultMessage: "OK" })
    },
    Popconfirm: {
      okText: intl.formatMessage({ id: "antd.popconfirm.okText", defaultMessage: "OK" }),
      cancelText: intl.formatMessage({ id: "antd.popconfirm.cancelText", defaultMessage: "Cancel" })
    },
    Transfer: {
      titles: ["", ""],
      searchPlaceholder: intl.formatMessage({ id: "antd.transfer.searchPlaceholder", defaultMessage: "Search here" }),
      itemUnit: intl.formatMessage({ id: "antd.transfer.itemUnit", defaultMessage: "item" }),
      itemsUnit: intl.formatMessage({ id: "antd.transfer.itemsUnit", defaultMessage: "items" }),
      remove: intl.formatMessage({ id: "antd.transfer.remove", defaultMessage: "Remove" }),
      selectCurrent: intl.formatMessage({ id: "antd.transfer.selectCurrent", defaultMessage: "Select current page" }),
      removeCurrent: intl.formatMessage({ id: "antd.transfer.removeCurrent", defaultMessage: "Remove current page" }),
      selectAll: intl.formatMessage({ id: "antd.transfer.selectAll", defaultMessage: "Select all data" }),
      removeAll: intl.formatMessage({ id: "antd.transfer.removeAll", defaultMessage: "Remove all data" }),
      selectInvert: intl.formatMessage({ id: "antd.transfer.selectInvert", defaultMessage: "Invert current page" })
    },
    Upload: {
      uploading: intl.formatMessage({ id: "antd.upload.uploading", defaultMessage: "Uploading..." }),
      removeFile: intl.formatMessage({ id: "antd.upload.removeFile", defaultMessage: "Remove file" }),
      uploadError: intl.formatMessage({ id: "antd.upload.uploadError", defaultMessage: "Upload error" }),
      previewFile: intl.formatMessage({ id: "antd.upload.previewFile", defaultMessage: "Preview file" }),
      downloadFile: intl.formatMessage({ id: "antd.upload.downloadFile", defaultMessage: "Download file" })
    },
    Empty: {
      description: intl.formatMessage({ id: "antd.empty.description", defaultMessage: "No Data" })
    },
    Icon: {
      icon: intl.formatMessage({ id: "antd.icon.icon", defaultMessage: "icon" })
    },
    Text: {
      edit: intl.formatMessage({ id: "antd.text.edit", defaultMessage: "Edit" }),
      copy: intl.formatMessage({ id: "antd.text.copy", defaultMessage: "Copy" }),
      copied: intl.formatMessage({ id: "antd.text.copied", defaultMessage: "Copied" }),
      expand: intl.formatMessage({ id: "antd.text.expand", defaultMessage: "Expand" })
    },
    PageHeader: {
      back: intl.formatMessage({ id: "antd.pageheader.back", defaultMessage: "Back" })
    },
    Form: {
      optional: intl.formatMessage({ id: "antd.form.optional", defaultMessage: "(optional)" }),
      defaultValidateMessages: {
        default: intl.formatMessage({ id: "antd.form.validationmessages.default", defaultMessage: "Field validation error for ${label}" }),
        required: intl.formatMessage({ id: "antd.form.validationmessages.required", defaultMessage: "Please enter ${label}" }),
        enum: intl.formatMessage({ id: "antd.form.validationmessages.enum", defaultMessage: "${label} must be one of [${enum}]" }),
        whitespace: intl.formatMessage({
          id: "antd.form.validationmessages.whitespace",
          defaultMessage: "${label} cannot be a blank character"
        }),
        date: {
          format: intl.formatMessage({ id: "antd.form.validationmessages.date.format", defaultMessage: "${label} date format is invalid" }),
          parse: intl.formatMessage({
            id: "antd.form.validationmessages.date.parse",
            defaultMessage: "${label} cannot be converted to a date"
          }),
          invalid: intl.formatMessage({ id: "antd.form.validationmessages.date.invalid", defaultMessage: "${label} is an invalid date" })
        },
        types: {
          string: typeTemplate,
          method: typeTemplate,
          array: typeTemplate,
          object: typeTemplate,
          number: typeTemplate,
          date: typeTemplate,
          boolean: typeTemplate,
          integer: typeTemplate,
          float: typeTemplate,
          regexp: typeTemplate,
          email: typeTemplate,
          url: typeTemplate,
          hex: typeTemplate
        },
        string: {
          len: intl.formatMessage({ id: "antd.form.validationmessages.string.len", defaultMessage: "${label} must be ${len} characters" }),
          min: intl.formatMessage({
            id: "antd.form.validationmessages.string.min",
            defaultMessage: "${label} must be at least ${min} characters"
          }),
          max: intl.formatMessage({
            id: "antd.form.validationmessages.string.max",
            defaultMessage: "${label} must be up to ${max} characters"
          }),
          range: intl.formatMessage({
            id: "antd.form.validationmessages.string.range",
            defaultMessage: "${label} must be between ${min}-${max} characters"
          })
        },
        number: {
          len: intl.formatMessage({ id: "antd.form.validationmessages.number.len", defaultMessage: "${label} must be equal to ${len}" }),
          min: intl.formatMessage({ id: "antd.form.validationmessages.number.min", defaultMessage: "${label} must be minimum ${min}" }),
          max: intl.formatMessage({ id: "antd.form.validationmessages.number.max", defaultMessage: "${label} must be maximum ${max}" }),
          range: intl.formatMessage({
            id: "antd.form.validationmessages.number.range",
            defaultMessage: "${label} must be between ${min}-${max}"
          })
        },
        array: {
          len: intl.formatMessage({ id: "antd.form.validationmessages.array.len", defaultMessage: "Must be ${len} ${label}" }),
          min: intl.formatMessage({ id: "antd.form.validationmessages.array.min", defaultMessage: "At least ${min} ${label}" }),
          max: intl.formatMessage({ id: "antd.form.validationmessages.array.max", defaultMessage: "At most ${max} ${label}" }),
          range: intl.formatMessage({
            id: "antd.form.validationmessages.array.range",
            defaultMessage: "The amount of ${label} must be between ${min}-${max}"
          })
        },
        pattern: {
          mismatch: intl.formatMessage({
            id: "antd.form.validationmessages.pattern.mismatch",
            defaultMessage: "${label} does not match the pattern ${pattern}"
          })
        }
      }
    },
    Image: {
      preview: intl.formatMessage({ id: "antd.global.preview", defaultMessage: "Preview" })
    }
  };
};

export default getTranslations;
