const defaultLocale = "en-US";

const supportedLanguages = [
  "de-DE",
  "en-GB",
  "en-US",
  "es-ES",
  "fr-FR",
  "it-IT",
  "nl-BE",
  "nl-NL",
  "pl-PL",
  "ar-KW",
  "sv-SE",
  "tr-TR",
  "da-DK",
  "ro-RO",
  "cs-CZ",
  "pt-PT",
  "hr-HR",
  "he-IL",
  "el-GR"
];
const supportedDemoLanguages = ["ar-AE"];

// Some (rare) languages/countries have a 3 letter code by default
const isValidLanguageCode = (code = "") => {
  return code.length === 2 || code.length === 3;
};

const getLanguageFromLocale = (language = "") => {
  if (languageHasZone(language)) {
    return language.split(/-|_/)[0];
  }

  return isValidLanguageCode(language) ? language : null;
};

const getZoneFromLocale = (language = "") => {
  if (languageHasZone(language)) {
    return language.split(/-|_/)[1];
  }

  return language.length === 2 ? language : null;
};

const languageHasZone = (language = "") => {
  const parts = language.split(/-|_/);

  return parts.length === 2 && isValidLanguageCode(parts[0]) && isValidLanguageCode(parts[1]);
};

const languageOptions = {
  "de-DE": "Deutsch",
  "en-GB": "English (GB)",
  "en-US": "English (US)",
  "es-ES": "Español",
  "fr-FR": "Français",
  "it-IT": "Italiano",
  "nl-BE": "Nederlands (BE)",
  "nl-NL": "Nederlands (NL)",
  "pl-PL": "Polska",
  "sv-SE": "Svenska",
  "da-DK": "Dansk",
  "ro-RO": "Română",
  "cs-CZ": "čeština",
  "pt-PT": "Português",
  "hr-HR": "Hrvatski",
  "tr-TR": "Türk",
  "ar-KW": "العربية (الكويت)",
  "he-IL": "עברית ",
  "ar-AE": "العربية (الإمارات العربية المتحدة) - DEMO",
  "el-GR": "Ελληνικά"
};

const storeLanguageAsCookie = (language) => {
  const d = new Date();
  d.setTime(d.getTime() + 180 * 24 * 60 * 60 * 1000); // expire after 6 months;
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `preferredLanguage=${language};${expires};path=/;secure=true`;
};

module.exports = {
  defaultLocale,
  supportedLanguages,
  supportedDemoLanguages,
  getZoneFromLocale,
  getLanguageFromLocale,
  storeLanguageAsCookie,
  languageHasZone,
  languageOptions
};
