const dutch = require("./compiled/nl/nl.json");
const hebrew = require("./compiled/he/he.json");
const turkish = require("./compiled/tr/tr.json");
const romanian = require("./compiled/ro/ro.json");

/* eslint-disable global-require */
const czech = { ...require("./compiled/cs/cs.json"), ...require("./compiled/cs/antd.json") };
const danish = { ...require("./compiled/da/da.json"), ...require("./compiled/da/antd.json") };
const portuguese = { ...require("./compiled/pt/pt.json"), ...require("./compiled/pt/antd.json") };

const french = { ...require("./compiled/fr/fr.json"), ...require("./compiled/fr/ssi.json") };
const arabic = { ...require("./compiled/ar/ar.json"), ...require("./compiled/ar/ssi.json") };
const polish = { ...require("./compiled/pl/pl.json"), ...require("./compiled/pl/ssi.json") };
const german = { ...require("./compiled/de/de.json"), ...require("./compiled/de/ssi.json"), ...require("./compiled/de/idehra.json") };
const spanish = { ...require("./compiled/es/es.json"), ...require("./compiled/es/ssi.json") };
const english = { ...require("./compiled/en/en.json"), ...require("./compiled/en/ssi.json"), ...require("./compiled/en/idehra.json") };
const englishGB = {
  ...require("./compiled/en/en.json"),
  ...require("./compiled/en/ssi.json"),
  ...require("./compiled/en/ssiGb.json"),
  ...require("./compiled/en/idehra.json")
};
const swedish = { ...require("./compiled/sv/sv.json"), ...require("./compiled/sv/ssi.json") };
const croatian = { ...require("./compiled/hr/hr.json"), ...require("./compiled/hr/ssi.json") };

const italian = { ...require("./compiled/it/it.json"), ...require("./compiled/it/antd.json"), ...require("./compiled/it/ssi.json") };
const greek = { ...require("./compiled/el/el.json"), ...require("./compiled/el/antd.json") };

const defaultMessages = english;

const {
  storeLanguageAsCookie,
  getLanguageFromLocale,
  defaultLocale,
  supportedLanguages,
  supportedDemoLanguages,
  languageOptions
} = require("./languageHelper");

const translationMessages = {
  "en-GB": englishGB,
  "en-US": english,
  "nl-NL": dutch,
  "nl-BE": dutch,
  "es-ES": spanish,
  "fr-FR": french,
  "de-DE": german,
  "it-IT": italian,
  "he-IL": hebrew,
  "pl-PL": polish,
  "ar-AE": arabic,
  "ar-KW": arabic,
  "sv-SE": swedish,
  "tr-TR": turkish,
  "da-DK": danish,
  "ro-RO": romanian,
  "cs-CZ": czech,
  "pt-PT": portuguese,
  "hr-HR": croatian,
  "el-GR": greek
};

const getTranslationsMessagesForLanguage = (language) => {
  const baseMessages = translationMessages[language];

  if (!language) {
    return defaultMessages;
  }

  if (baseMessages) {
    return baseMessages;
  }

  return defaultMessages;
};

const getLanguageFromCookie = () => {
  const name = `preferredLanguage=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(";");
  let language = null;
  cookies.forEach((cookie) => {
    if (cookie.includes(name)) {
      language = cookie.trim().substr(name.length);
    }
  });
  return language;
};

module.exports = {
  getLanguageFromLocale,
  getLanguageFromCookie,
  getTranslationsMessagesForLanguage,
  storeLanguageAsCookie,
  supportedLanguages,
  supportedDemoLanguages,
  defaultLocale,
  languageOptions
};
